import React from 'react';
import { connect } from 'react-redux';

import { calculateRendement } from '../../modules/calculations';

import { GroupedLabeledInput } from '../../components/Inputs/Inputs';

import { gTagEvent, timeoutTime } from '../../modules/analytics';

import stylesForm from '../../componentStyles/component.scss';

export class QuickSdeCalculator extends React.Component {
  state = {
    placeholders: {
      dakoppervlakte: '30'
    },
    values: {
      dakoppervlakte: {}
    },
    options: {
      dakoppervlakte: [
        { title: '> 5000 m2', value: '> 5000' },
        { title: '1000 - 5000 m2', value: '1000-5000' },
        { title: '< 1000 m2', value: '< 1000' }
      ]
    }
  };
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    if (
      !props.calculations.rendement.dakoppervlakte ||
      !props.calculations.rendement.dakoppervlakte.value
    ) {
      props.calculations.rendement.dakoppervlakte = this.state.options.dakoppervlakte[0];
    }
  }

  handleChange(e) {
    const inputKey = e.target.getAttribute('id');
    let inputValue = e.target.value;
    const convertTo = e.target.getAttribute('convert-to');
    if (convertTo) {
      switch (convertTo) {
        case 'integer':
          if (inputValue === 'string' || typeof inputValue === 'number') {
            inputValue = inputValue.replace(new RegExp(/./g), '');
            inputValue = inputValue.replace(new RegExp(/,/g), '.');
            inputValue = parseInt(inputValue);
          }
          break;
        default:
          break;
      }
    }
    this.setState(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [inputKey]: inputValue
        }
      };
    });
    this.props.calculateRendement({ [inputKey]: inputValue });
    this.trackFormData();
  }

  handleBlur(e) {
    const inputKey = e.target.getAttribute('id');
    let inputValue = e.target.value;
    const convertTo = e.target.getAttribute('convert-to');
    switch (convertTo) {
      case 'integer':
        break;
      case 'currency':
        inputValue = inputValue.replace(new RegExp(/,/g), '.');
        inputValue = inputValue.replace(new RegExp(/_/g), '0') * 1;
        inputValue = inputValue.toFixed(2);
        break;
      default:
        break;
    }
    this.setState(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [inputKey]: inputValue
        }
      };
    });
    this.props.calculateRendement({ [inputKey]: inputValue });
    this.trackFormData();
  }

  handleSelectChange(name, state) {
    this.setState(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: state
        }
      };
    });
    this.props.calculateRendement({ [name]: state });
    this.trackFormData();
  }

  componentDidMount() {
    if (
      this.props.calculations &&
      this.props.calculations.rendement &&
      Object.keys(this.props.calculations.rendement).length > 0
    ) {
      if (!this.props.calculations.rendement.dakoppervlakte.title) {
        this.props.calculations.rendement.dakoppervlakte = this.state.options.dakoppervlakte[0];
        this.handleSelectChange(
          'dakoppervlakte',
          this.state.options.dakoppervlakte[0]
        );
      }
      this.setState(prevState => {
        return {
          ...prevState,
          values: {
            ...this.props.calculations.rendement
          }
        };
      });
    }
  }

  trackFormData() {
    if (this.trackingForm) {
      clearTimeout(this.trackingForm);
    }
    this.trackingForm = setTimeout(() => {
      gTagEvent({
        event: 'handleForm',
        event_category: 'quickSdeCalculatorForm',
        event_label: JSON.stringify(this.props.calculations.rendement)
      });
    }, timeoutTime);
  }

  render() {
    return (
      <>
        <GroupedLabeledInput
          className={stylesForm.component__form__wrapper__inputBlock}
          selectClassName={
            stylesForm.component__form__wrapper__inputBlock__selectWrapper
          }
          label="Dak oppervlakte"
          type="select"
          name="dakoppervlakte"
          activeItem={this.props.calculations.rendement.dakoppervlakte}
          placeholder={this.state.placeholders.dakoppervlakte}
          onChange={this.handleSelectChange}
          items={this.state.options.dakoppervlakte}
          required={true}
        />
      </>
    );
  }
}

export class QuickSdeCalculatorPersonalInfoComponent extends QuickSdeCalculator {
  state = {
    placeholder: {
      name: 'Voornaam Achternaam',
      phone: '',
      email: 'email@domein.nl'
    }
  };

  render() {
    return (
      <>
        <GroupedLabeledInput
          className={stylesForm.component__form__wrapper__inputBlock}
          label="Uw naam"
          type="text"
          name="name"
          value={this.props.calculations.rendement.name}
          placeholder={this.state.placeholder.name}
          onChange={this.handleChange}
          required={true}
        />
        <GroupedLabeledInput
          className={stylesForm.component__form__wrapper__inputBlock}
          label="Telefoon"
          type="number"
          name="phone"
          value={this.props.calculations.rendement.phone}
          placeholder={this.state.placeholder.phone}
          onChange={this.handleChange}
          mask={[
            /[0-9]/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/
          ]}
        />
        <GroupedLabeledInput
          className={stylesForm.component__form__wrapper__inputBlock}
          label="E-mailadres"
          type="email"
          name="email"
          value={this.props.calculations.rendement.email}
          placeholder={this.state.placeholder.email}
          onChange={this.handleChange}
        />
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return { calculateRendement: args => dispatch(calculateRendement(args)) };
};

export default connect(
  state => state,
  mapDispatchToProps
)(QuickSdeCalculator);
export const QuickSdeCalculatorPersonalInfo = connect(
  state => state,
  mapDispatchToProps
)(QuickSdeCalculatorPersonalInfoComponent);
