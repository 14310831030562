import React from 'react';
import { Link } from 'react-router-dom';

import Icons from '../Icons';

import { HashLink } from 'react-router-hash-link';

import { ActionButton } from '../Buttons/Buttons';

import stylesIndex from './index.scss';
import stylesElements from './Elements.scss';

export const Stripes = props => (
  <div className={stylesIndex.header__stripes}>
    <Tile />
    <Tile />
    <Tile />
    <Tile />
    <Tile />
  </div>
);

export const Tile = props => <span />;

export const Intro = props => {
  return (
    <section className={stylesElements.header__intro}>
      <div className={stylesElements.header__intro__container}>
        <div className={stylesElements.header__intro__container__announcements}>
          <span>Nieuw!</span>
          <Link to="/assets/docs/starborn_folder_v201801.pdf" target="_blank">
            GRATIS uw dak renoveren! <Icons icon={'arrow-right'} />
          </Link>
        </div>
        <h1 className={stylesElements.header__intro__container__title}>
          Samen naar een zonnige toekomst!
        </h1>
        <p className={stylesElements.header__intro__container__content}>
          Wij ontwikkelen, investeren en adviseren over grootschalige
          zonneparken op daken, velden, en water.
        </p>
        <ul className={stylesElements.header__intro__container__actions}>
          <li>
            <ActionButton to="/calculator">
              Gratis uw rendement berekenen
            </ActionButton>
          </li>
          <li>
            <ActionButton
              to="/assets/docs/starborn_brochure_v201801.pdf"
              target="_blank"
              className={
                stylesElements['header__intro__container__actions--orange']
              }
            >
              Download Brochure
            </ActionButton>
          </li>
        </ul>
      </div>
    </section>
  );
};

export const ContentIntro = props => {
  return (
    <section className={stylesElements.header__intro}>
      <div className={stylesElements.header__intro__container}>
        {props.intro.announcement && (
          <div
            className={stylesElements.header__intro__container__announcements}
          >
            <span>{props.intro.announcement.label}</span>
            {props.intro.announcement.link.indexOf('#') > -1 ? (
              <HashLink
                to={props.intro.announcement.link}
                {...props.intro.announcement}
              >
                {props.intro.announcement.title}
                <Icons icon={props.intro.announcement.icon || 'arrow-right'} />
              </HashLink>
            ) : (
              <Link
                to={props.intro.announcement.link}
                {...props.intro.announcement}
              >
                {props.intro.announcement.title}
                <Icons icon={props.intro.announcement.icon || 'arrow-right'} />
              </Link>
            )}
          </div>
        )}
        <h1 className={stylesElements.header__intro__container__title}>
          {props.intro.header.title}
        </h1>
        <div className={stylesElements.header__intro__container__content}>
          {typeof props.intro.header.description === 'function' &&
            props.intro.header.description({ ...props })}
        </div>
        <ul className={stylesElements.header__intro__container__actions}>
          <li>
            {props.intro.action && (
              <ActionButton
                to={props.intro.action.link}
                icon={props.intro.action.icon || 'arrow-right'}
                {...props.intro.action}
              >
                {props.intro.action.label}
              </ActionButton>
            )}
          </li>
        </ul>
      </div>
    </section>
  );
};

export class FormIntro extends React.Component {
  // props.styles = stylesElements.header__intro__container__content;
  state = {
    contentState: false
  };

  constructor(props) {
    super(props);
    this.toggleState = this.toggleState.bind(this);
  }

  toggleState() {
    this.setState(prevState => {
      return {
        ...prevState,
        contentState: !prevState.contentState
      };
    });
  }

  render() {
    return (
      <section
        className={`${stylesElements.header__intro} ${
          stylesElements['header__intro--no-icon']
        }`}
      >
        <div
          className={stylesElements.header__intro__container}
          data-has-announcements={this.props.intro.announcement ? true : false}
        >
          {this.props.intro.announcement && (
            <div
              className={stylesElements.header__intro__container__announcements}
            >
              <span>{this.props.intro.announcement.label}</span>
              <Link
                to={this.props.intro.announcement.link}
                {...this.props.intro.announcement}
              >
                {this.props.intro.announcement.title}
                <Icons
                  icon={this.props.intro.announcement.icon || 'arrow-right'}
                />
              </Link>
            </div>
          )}
          <h1 className={stylesElements.header__intro__container__title}>
            {this.props.intro.header.title}
          </h1>
          <div
            className={
              stylesElements.header__intro__container__content +
              ' ' +
              (this.state.contentState
                ? stylesElements['header__intro__container__content--active']
                : '')
            }
          >
            {typeof this.props.intro.header.description === 'function' &&
              this.props.intro.header.description({ ...this.props })}
            {this.props.intro.header.shorterDesc && (
              <button
                className={
                  stylesElements.header__intro__container__content__moreInfo
                }
                onClick={this.toggleState}
              >
                {!this.state.contentState ? 'Lees meer' : 'Verberg'}
              </button>
            )}
          </div>
        </div>
      </section>
    );
  }
}
