import React from 'react';

import { imagePath } from '../../utils/assetUtils';

import { OVER_ONS, DIENSTEN, VACATURES, CONTACT } from '../../constants';

import { TabbedBlock } from '../../components/Section';

import {
  DienstenContent,
  ProjectOntwikkeling,
  Aankoopbegeleiding,
  Consulting,
  Epc,
  Financial,
  Zonnepanelen
} from './diensten';

import CalculatorContentComponent from '../FormPage/CalculatorContent';
import QuickCalculatorContentComponent from '../FormPage/QuickCalculatorContent';
import PerceelAanbieden from '../FormPage/PerceelAanbieden';

const image_asset_01 = imagePath('images/assets-01.svg');
const image_asset_02 = imagePath('images/assets-02.svg');
const image_asset_03 = imagePath('images/assets-03.svg');
const image_asset_04 = imagePath('images/assets-04.svg');

export const OveronsContent = () => (
  <div>
    <p>
      Ons team heeft jarenlange ervaring met projectontwikkeling in
      verschillende landen zoals Nederland, Duitsland, Spanje, Hongarije, VS,
      Zuid Afrika, India en Vietnam. Starborn heeft de aandacht nu gericht op
      Nederland en Hongarije, twee landen met een grote behoefte aan duurzame
      energie projecten.
    </p>
    <p>
      Wij geloven in de nut en noodzaak van de energietransitie. Met ons breed
      georiënteerd team staan wij klaar om u bij te staan in de advisering van
      zonnepark-gerelateerde onderwerpen. Omdat wij ruime ervaring hebben in
      zowel de projectontwikkeling als de financiering van zonneparken zijn wij
      uitermate in goed staat om u te begeleiden in het succesvol realiseren van
      uw zonnepark.
    </p>
    <h2>Advies &amp; Ontwikkeling</h2>
    <p>
      Er komt veel kijken bij het ontwikkelen en realiseren van zonneparken.
      Wilt u voor een maximum installatie vermogen gaan? Hoe houdt dit verband
      met de kosten en opbrengsten? Welk subsidietarief kunt u het beste
      aanvragen en heeft u alle benodigde documenten in orde? U komt voor deze
      en nog veel meer vragen te staan. Wij staan u graag bij.
    </p>
    <h2>Internationaal</h2>
    <p>
      Heeft u liever een zonnepark met een hoger rendement? Dan staat wij u
      graag bij in de aankoop van zonneparken in Oost-Europa en Azië. Het is
      belangrijk om eerst de risicobereidheid te peilen, hoger rendement gaat
      immers gepaard met een hoger risico.
    </p>
  </div>
);

export const OveronsContentDesc = () => (
  <>
    Onze missie is het versnellen van zonne-energie initiatieven in binnen- en
    buitenland. De visie is om voorrang te geven aan daken en braakliggende
    velden en wateren.
  </>
);

export const IntroductionContent = () => (
  <div>
    <p>
      Ons team heeft jarenlange ervaring met zonneparken in verschillende landen
      zoals Nederland, Duitsland, Spanje, Hongarije, VS, Zuid Afrika, India en
      Vietnam. Starborn heeft de aandacht nu gericht op Nederland en Hongarije,
      twee landen met een grote behoefte aan duurzame energie projecten.
    </p>
    <p>
      Ons missie is het versnellen van zonne-energie initiatieven in binnen- en
      buitenland. De visie is om voorrang te geven aan daken en braakliggende
      velden en wateren.
    </p>
  </div>
);

export const DakContent = () => (
  <section>
    <p>
      Is uw dak toe aan vervanging? En wilt u dit gratis laten uitvoeren? Lees
      dan snel verder.
    </p>
    <h3>Hoe werkt het?</h3>
    <p>
      Stel dat u uw dak wilt renoveren of vervangen. Indien uw dak geschikt is
      voor zonnepanelen, kunnen wij de werkzaamheden geheel kosteloos voor u
      uitvoeren. Vervolgens plaatsen we de zonnepanelen erop. Het voordeel voor
      u? Uw dak is weer in goede staat. Bovendien kunt u de elektriciteit
      verbruiken met korting en wordt u aan het einde van de looptijd eigenaar
      van de zonnepanelen. Zonder geld uit te geven, is uw dak vervangen of
      gerenoveerd en draagt u ook nog eens bij aan een duurzame toekomst!
    </p>
  </section>
);

export const RendementsBerekeningContent = () => (
  <p>
    Om de verduurzaming van de energiebehoefte te versnellen hebben we een
    gratis calculator ontwikkelt waarmee u eenvoudig en direct het rendement van
    een zonnesysteem op uw dak kunt berekenen. De calculator heeft hiervoor
    enkele gegevens van u nodig die u hieronder kunt invullen. Naast de door u
    ingevulde gegevens maakt de calculator aannamen op basis van marktnormen.
  </p>
);

export const VacatureContent = key => {
  const content = initStateContent().vacatures;
  return (
    <TabbedBlock
      key={key}
      vertical={true}
      content={content}
      tabs={content.list}
    />
  );
};

export const VacatureContent1 = props => {
  return (
    <>
      <h2 id="BusinessDevelopmentIntern" onClick={props.menuHandler}>
        Business Development Intern
      </h2>
      <p>
        Starborn is looking for an enthusiastic Business Development Intern to
        help with the optimization and running of the business and project
        development activities of the company. The intern will help create new
        project leads and optimize and facilitate the permit and design of the
        existing projects.
      </p>
      <p>
        Ultimately, you will play a supportive role in the creating new
        sustainable energy campaigns, get experience in the business and project
        development process of lead to opportunity, opportunity to project,
        project to sustainable solutions.
      </p>
      <h3>Responsibilities:</h3>
      <ul>
        <li>
          Qualifying leads from marketing campaigns as sales opportunities
        </li>
        <li>
          Contacting potential clients and authorities through cold calls and
          emails
        </li>
        <li>
          Schedule and plan meetings and appointments with leads and relevant
          authorities
        </li>
        <li>
          Reporting on weekly sustainable marketing campaigns to Project
          Development Manager
        </li>
        <li>Sorting and distributing communications in a timely manner</li>
        <li>
          Acting as a point of contact for existing and potential customers{' '}
        </li>
        <li>Identifying local business opportunities and challenges</li>
      </ul>
      <h3>Requirement:</h3>
      <ul>
        <li>Excellent communication and negotiation skills</li>
        <li>Ability to deliver engaging presentations</li>
        <li>Interest in Sustainability</li>
        <li>BSc degree or in BSc studies</li>
        <li>Proficiency in MS Office </li>
        <li>Excellent organizational and time management skills</li>
        <li>Analytical abilities and aptitude in problem-solving</li>
      </ul>
    </>
  );
};

export const VacatureContent2 = props => {
  return (
    <>
      <h2 onClick={props.menuHandler}>Receptioniste</h2>
      <p>
        Als receptionist(e)/gastvrouw ben je verantwoordelijk voor een
        onvergetelijke eerste indruk. Loop je 24/7 met een glimlach op je
        gezicht, ben je sociaal en is klantvriendelijkheid je tweede natuur? Heb
        jij zin om in een jong team aan de slag te gaan? Ben je commercieel
        representatief en zeer servicegericht en vind je het heerlijk om van het
        eerste contact tot het laatste moment volledig verantwoordelijk te zijn
        voor een klant? En ben je op zoek naar een super afwisselende baan voor
        32 uur in de week? Dan zoeken wij jou!
      </p>
      <h3>De dagelijkse verantwoordelijkheden zijn:</h3>
      <ul>
        <li>Bezoekers ontvangen</li>
        <li>Afspraken plannen</li>
        <li>Bellers te woord staan</li>
        <li>Telefonisch contact met gemeenten en provincies</li>
        <li>Bezoekers</li>
        <li>Bezoekers</li>
        <li>Bezoekers</li>
      </ul>
      <h3>Wat mag je van ons verwachten?</h3>
      <ul>
        <li>
          Een leuke baan met een fantastisch team binnen een jong en dynamisch
          bedrijf
        </li>
        <li>Trainingsprogramma’s met salaris groeimogelijkheden</li>
        <li>Uitstekende doorgroeimogelijkheden</li>
        <li>Bonusregeling</li>
      </ul>
      <h3>Wat verwachten wij van jou?</h3>
      <ul>
        <li>Je hebt een afgeronde MBO niveau 4 opleiding.</li>
        <li>Je hebt een talenknobbel voor de Nederlandse en Engelse taal.</li>
        <li>Je vindt het geweldig om anderen te helpen.</li>
        <li>Je maakt in elke omgeving een verpletterende indruk.</li>
        <li>
          Je vindt het heerlijk om heel de dag te communiceren en kan dat dan
          ook goed.
        </li>
        <li>Je bent zowel gestructureerd als flexibel.</li>
        <li>
          Je weet altijd voor alles en iedereen de juiste oplossing te vinden.
        </li>
      </ul>
    </>
  );
};

export const initStateContent = props => {
  return {
    'over-ons': {
      title: 'Over ons',
      content: <OveronsContent />,
      link: {
        alt: 'Lees meer over Starborn',
        url: OVER_ONS,
        text: 'Lees verder'
      },
      main: {
        announcement: undefined,
        header: {
          title: 'Starborn DNA',
          description: ({ styles }) => <OveronsContentDesc />,
          image: image_asset_01
        },
        action: null
      }
    },
    diensten: {
      icon: 'tiles',
      title: 'Diensten',
      intro:
        'Met ruime ervaring in de advisering, ontwikkeling, aankoop en verkoop van zonneparken is Starborn úw partner in zonne-energie. Met onze expertise worden uw wensen werkelijkheid.',
      content:
        'Met ons breed georiënteerd team staan wij klaar om u bij te staan in de advisering van zonnepark-gerelateerde onderwerpen. Omdat wij ruime ervaring hebben in zowel de projectontwikkeling als de financiering van zonneparken zijn wij uitermate goed in staat om u te begeleiden in het succesvol realiseren van uw zonnepark.',
      link: {
        alt: 'Lees meer over Starborn',
        url: DIENSTEN,
        text: 'Lees verder'
      },
      customBlocks: [DienstenContent],
      main: {
        announcement: undefined,
        header: {
          title: 'Uw wensen worden werkelijkheid',
          description: ({ styles }) => (
            <>
              Met ruime ervaring in de advisering, ontwikkeling, aankoop en
              verkoop van zonneparken is Starborn úw partner in zonne-energie.
              Met onze expertise worden uw wensen werkelijkheid.
            </>
          ),
          image: image_asset_02
        },
        action: undefined
      },
      list: [
        {
          title: 'Projectontwikkeling',
          icon: 'development',
          content: `Starborn ontwikkelt zonne-energieprojecten in eigen beheer. Het proces begint met het vinden van een geschikte locatie en omvat tevens het aanvragen van subsidie en de benodigde vergunningen. De feitelijke bouw van het zonne-energieproject is uiteindelijk het resultaat.`,
          fullContent: props => <ProjectOntwikkeling {...props} />
        },
        {
          title: 'Consulting',
          icon: 'guidance',
          content: `Wilt u zelf het heft in handen nemen voor uw eigen energieproject, maar heeft u vragen over een of meer onderdelen in het proces? Ook dan staan wij u graag bij.`,
          fullContent: props => <Consulting {...props} />
        },
        {
          title: 'EPC',
          icon: 'approvedDoc',
          content: `Voor de bouw van zonneparken werken we nauw samen met onze partners. Gezamenlijk ontwerpen we de zonneparken volgens hoge industrie-standaarden.`,
          fullContent: props => <Epc {...props} />
        },
        {
          title: 'Zonnepanelen',
          icon: 'panels',
          content: `Starborn levert alleen hoogwaardige zonnepanelen. Zo kunt u er zeker van zijn dat banken de financiering van uw project(en) zullen accepteren.`,
          fullContent: props => <Zonnepanelen {...props} />
        },
        {
          title: 'Aankoopbegeleiding',
          icon: 'researchDocs',
          content: `Ons team heeft internationale ervaring in zonneparken. Zodoende zijn wij uitstekend in staat om u te begeleiden bij de aankoop van projecten in een select aantal landen.`,
          fullContent: props => <Aankoopbegeleiding {...props} />
        },
        {
          title: 'Financiële Diensten',
          icon: 'synergy',
          content: `Zet de financiering van uw zonne-energieproject u nog voor vraagtekens? Starborn is bereid om eigen kapitaal te investeren in projecten van derden. Bovendien kunnen we u helpen bij het aantrekken van bijvoorbeeld vreemd vermogen.`,
          fullContent: props => <Financial {...props} />
        }
      ]
    },
    vacatures: {
      title: 'Vacatures',
      content:
        "Heb jij een profiel dat bij ons past? Stuur dan een mail naar info@starborn.nl ovv 'Vacature' en voeg een recente CV en motivatiebrief toe.",
      link: {
        alt: 'Lees meer over Starborn',
        url: VACATURES,
        text: 'Lees verder'
      },
      customBlocks: [VacatureContent],
      list: [
        {
          title: 'Business Development Intern',
          icon: 'settings-icon',
          content: `Starborn ontwikkelt zonne-energieprojecten in eigen beheer. Het proces begint met het vinden van een geschikte locatie en omvat tevens het aanvragen van subsidie en de benodigde vergunningen. De feitelijke bouw van het zonne-energieproject is uiteindelijk het resultaat.`,
          fullContent: props => <VacatureContent1 {...props} />
        },
        {
          title: 'Receptioniste',
          icon: 'settings-icon',
          content: `Als receptionist(e)/gastvrouw ben je verantwoordelijk voor een onvergetelijke eerste indruk. Loop je 24/7 met een glimlach op je gezicht, ben je sociaal en is klantvriendelijkheid je tweede natuur? Heb jij zin om in een jong team aan de slag te gaan? Ben je commercieel representatief en zeer servicegericht en vind je het heerlijk om van het eerste contact tot het laatste moment volledig verantwoordelijk te zijn voor een klant? En ben je op zoek naar een super afwisselende baan voor 32 uur in de week? Dan zoeken wij jou!`,
          fullContent: props => <VacatureContent2 {...props} />
        }
      ],
      main: {
        announcement: {
          label: 'Solliciteer nu!',
          icon: '',
          title: 'Business Development Intern',
          link: '#BusinessDevelopmentIntern'
        },
        header: {
          title: 'Sterren gezocht',
          description: ({ styles }) => (
            <>
              Wij zoeken ambitieuze sterren met een warm hart voor duurzaamheid.
              Heb jij passie voor groene energie en dan met name voor
              zonne-energie? Neem dan contact met ons op
            </>
          ),
          image: image_asset_03
        },
        action: {
          link:
            'mailto:info@starborn.nl?subject=Sollicitatie op vacature...&body=<<Vergeet niet je CV en motivatiebrief als bijlage toe te voegen.>>',
          icon: '',
          label: 'Mail CV en motivatiebrief',
          target: '_blank'
        }
      }
    },
    contact: {
      title: '',
      content: <></>,
      link: {
        alt: 'Lees meer over Starborn',
        url: CONTACT,
        text: 'Lees verder'
      },
      main: {
        announcement: undefined,
        header: {
          title: 'Vragen of opmerkingen?',
          description: ({ styles }) => (
            <>
              Laat het ons weten via 020 244 37 34 of stuur een mail naar
              info@starborn.nl
            </>
          ),
          image: image_asset_04
        },
        action: undefined
      }
    },
    calculator: {
      title: 'Dak Specificaties',
      content: <CalculatorContentComponent />,
      main: {
        header: {
          title: 'SDE+ Calculator',
          shorterDesc: true,
          description: ({ styles }) => (
            <div>
              <p>
                <strong style={{ display: 'block', marginBottom: '20px' }}>
                  Wilt u weten wat een zonnesysteem op het dak u oplevert? Vul
                  de gegevens hiernaast in voor een gratis rendementsberekening.
                </strong>
              </p>
              <p className={styles.moreInfo}>
                De calculator maakt gebruikt van de door u ingevulde gegevens en
                marktconforme aannamen om het rapport op te stellen. Het rapport
                berekent drie scenario’s zodat u deze met elkaar kunt
                vergelijken.
              </p>
              <p className={styles.moreInfo}>
                U heeft de optie om zelf te investeren of om uw dak te verhuren
                ten behoeve van een zonnesysteem. Als u besluit om zelf te
                investeren dan staan wij u graag bij als adviseur c.q aannemer
                in het gehele proces. Wilt u uw dak liever verhuren, dan kunnen
                wij u een aantrekkelijk retributievoorstel doen. Het voordeel
                van het verhuren is dat u geen omkijken heeft naar de processen
                rondom het realiseren van het zonnesysteem.
              </p>
              <p className={styles.moreInfo}>
                NB. Deze calculator is geoptimaliseerd voor zonnesystemen op
                daken groter dan 1000 m2.
              </p>
            </div>
          )
        },
        action: {
          link: CONTACT,
          icon: '',
          label: 'Mail ons'
        }
      }
    },
    'zon-op-mijn-dak': {
      title: '',
      content: <QuickCalculatorContentComponent />,
      main: {
        header: {
          title: 'Zonnedaken',
          description: ({ styles }) => (
            <div>
              <p>
                <strong style={{ display: 'block', marginBottom: '20px' }}>
                  Wilt u weten wat het plaatsen van zonnepanelen kost, wat het
                  rendement van een dergelijk systeem is en of u in aanmerking
                  komt voor dakverhuur? Vul de gegevens in en wij nemen zsm
                  contact met u op.
                </strong>
              </p>
            </div>
          )
        },
        action: {
          link: CONTACT,
          icon: '',
          label: 'Mail ons'
        }
      }
    },
    'perceel-aanbieden': {
      title: 'Vul in voor offerte',
      content: <PerceelAanbieden formPage={true} />,
      main: {
        header: {
          title: 'Uw Perceel Aanbieden?',
          shorterDesc: true,
          description: ({ styles }) => (
            <div>
              <p>
                <strong style={{ display: 'block', marginBottom: '20px' }}>
                  Zonneparken zijn een goed alternatief voor percelen waarvan
                  het huidige gebruik onvoldoende inkomsten genereert.
                </strong>
              </p>
              <p className={styles.moreInfo}>
                Het verpachten van uw perceel voor de exploitatie van een
                zonnepark kan voor u een aantrekkelijk jaaromzet genereren. U
                kunt uw perceel eventueel ook te koop aanbieden. Heeft u
                interesse in het verpachten danwel verkopen van uw perceel, vul
                dan de gegevens in voor een offerte!
              </p>
              <h3 className={styles.moreInfo}>Duurzaamheid</h3>
              <p className={styles.moreInfo}>
                Met het beschikbaar stellen van uw percelen steunt u de
                uitbreiding van de productie van hernieuwbare energie en helpt u
                de CO2-uitstoot te verminderen. Bovendien kunt u het perceel op
                termijn weer gebruiken voor het oorspronkelijke doel.
              </p>
              <h3 className={styles.moreInfo}>Dubbele bestemming</h3>
              <p className={styles.moreInfo}>
                Starborn ontwikkelt nieuwe oplossingen die een dubbel
                grondgebruik mogelijk maken. Zonneparken kunnen bijvoorbeeld op
                een zodanige manier worden geïnstalleerd dat begrazing of andere
                landbouwactiviteiten mogelijk blijven.
              </p>
            </div>
          )
        },
        action: {}
      }
    },
    // HOMEPAGE BLOCKS
    landGezocht: {
      icon: 'guide',
      title: 'Percelen voor zonneparken',
      intro: `Zonneparken zijn een goed alternatief voor percelen waarvan het huidige gebruik onvoldoende inkomsten genereert. Het verpachten van uw land voor de exploitatie van een zonnepark kan voor u een aantrekkelijk jaaromzet genereren. U kunt uw perceel eventueel ook te koop aanbieden. Heeft u interesse in het verpachten danwel verkopen van uw perceel, vul dan de gegevens in voor een offerte!`,
      list: [
        {
          title: 'Voordeel 1: Duurzaamheid',
          content: `Met het beschikbaar stellen van uw percelen steunt u de uitbreiding van de productie van hernieuwbare energie en helpt u de CO2-uitstoot te verminderen. Bovendien kunt u het perceel op termijn weer gebruiken voor het oorspronkelijke doel.`
        },
        {
          title: 'Voordeel 2: Dubbele bestemming',
          content: `Starborn ontwikkelt nieuwe oplossingen die een dubbel grondgebruik mogelijk maken. Zonneparken kunnen bijvoorbeeld op een zodanige manier worden geïnstalleerd dat begrazing of andere landbouwactiviteiten mogelijk blijven.`
        }
      ]
    },
    rendementsBerekening: {
      title: 'Gratis rendementsberekening',
      icon: 'state',
      intro: <RendementsBerekeningContent />
    },
    blocks: {
      icon: 'layers',
      introduction: {
        title: 'Over ons',
        content: <IntroductionContent />,
        link: {
          alt: 'Lees meer over Starborn',
          url: OVER_ONS,
          text: 'Lees verder'
        }
      },
      dakContent: {
        icon: 'diamont',
        title: 'Gratis uw dak renoveren?',
        content: <DakContent />,
        link: {
          alt: 'Download Folder',
          url: '/assets/docs/starborn_folder_v201801.pdf',
          text: 'Download Folder',
          target: '_blank'
        }
      }
    }
  };
};
