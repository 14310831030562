import React from 'react';
// import styles from "./SdeCalculator.scss";
import { connect } from 'react-redux';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';
// import emailMask from 'text-mask-addons/dist/emailMask';

import { calculateRendement } from '../../modules/calculations';

import { GroupedLabeledInput } from '../../components/Inputs/Inputs';

// import styles from "./SdeCalculator.scss";
import stylesForm from '../../componentStyles/component.scss';

export class SdeCalculator extends React.Component {
  state = {
    placeholders: {
      oppervlakte: '3.000',
      elekPrijs: '0,11',
      stroomverbruik: '30.000',
      orientatie: 'NZ',
      typeDak: 'Plat',
      schaduw: 'Nee',
      helling: '30'
    },
    values: {
      oppervlakte: '',
      elekPrijs: '0,39',
      stroomverbruik: '',
      orientatie: {},
      typeDak: {},
      schaduw: {},
      helling: ''
    },
    options: {
      orientatie: [
        { title: 'Noord / Zuid', value: 'NZ' },
        { title: 'Oost / West', value: 'OW' },
        { title: 'Noord', value: 'N' },
        { title: 'Oost', value: 'O' },
        { title: 'Zuid', value: 'Z' },
        { title: 'West', value: 'W' }
      ],
      typeDak: [
        { title: 'Plat', value: 'Plat' },
        { title: 'Schuin', value: 'Schuin' }
      ],
      schaduw: [{ title: 'Ja', value: 'Ja' }, { title: 'Nee', value: 'Nee' }]
    }
  };
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    if (
      !props.calculations.rendement.orientatie ||
      !props.calculations.rendement.orientatie.value
    ) {
      props.calculations.rendement.orientatie = this.state.options.orientatie[0];
    }
    if (
      !props.calculations.rendement.typeDak ||
      !props.calculations.rendement.typeDak.value
    ) {
      props.calculations.rendement.typeDak = this.state.options.typeDak[0];
    }
    if (
      !props.calculations.rendement.schaduw ||
      !props.calculations.rendement.schaduw.value
    ) {
      props.calculations.rendement.schaduw = this.state.options.schaduw[0];
    }
  }

  handleChange(e) {
    const inputKey = e.target.getAttribute('id');
    let inputValue = e.target.value;
    const convertTo = e.target.getAttribute('convert-to');
    if (convertTo) {
      switch (convertTo) {
        case 'integer':
          if (inputValue === 'string' || typeof inputValue === 'number') {
            inputValue = inputValue.replace(new RegExp(/./g), '');
            inputValue = inputValue.replace(new RegExp(/,/g), '.');
            inputValue = parseInt(inputValue);
          }
          break;
        default:
          break;
      }
    }
    this.setState(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [inputKey]: inputValue
        }
      };
    });
    this.props.calculateRendement({ [inputKey]: inputValue });
  }

  handleBlur(e) {
    const inputKey = e.target.getAttribute('id');
    let inputValue = e.target.value;
    const convertTo = e.target.getAttribute('convert-to');
    switch (convertTo) {
      case 'integer':
        break;
      case 'currency':
        inputValue = inputValue.replace(new RegExp(/,/g), '.');
        inputValue = inputValue.replace(new RegExp(/_/g), '0') * 1;
        inputValue = inputValue.toFixed(2);
        break;
      default:
        break;
    }
    this.setState(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [inputKey]: inputValue
        }
      };
    });
    this.props.calculateRendement({ [inputKey]: inputValue });
  }

  handleSelectChange(name, state) {
    this.setState(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: state
        }
      };
    });
    this.props.calculateRendement({ [name]: state });
  }

  componentDidMount() {
    if (
      this.props.calculations &&
      this.props.calculations.rendement &&
      Object.keys(this.props.calculations.rendement).length > 0
    ) {
      if (!this.props.calculations.rendement.orientatie.title) {
        this.props.calculations.rendement.orientatie = this.state.options.orientatie[0];
        this.handleSelectChange('orientatie', this.state.options.orientatie[0]);
      }
      this.setState(prevState => {
        return {
          ...prevState,
          values: {
            ...this.props.calculations.rendement
          }
        };
      });
    }
  }

  render() {
    return (
      <>
        <input
          name="C4"
          type="hidden"
          value={this.props.calculations.rendement.oppervlakte}
          onChange={this.handleChange}
        />
        <input
          name="C5"
          type="hidden"
          value={this.props.calculations.rendement.elekPrijs}
          onChange={this.handleChange}
        />
        <input
          name="C6"
          type="hidden"
          value={this.props.calculations.rendement.stroomverbruik}
          onChange={this.handleChange}
        />
        <input
          name="C7"
          type="hidden"
          value={this.props.calculations.rendement.orientatie.title || ''}
          onChange={this.handleChange}
        />
        <input
          name="C8"
          type="hidden"
          value={this.props.calculations.rendement.typeDak.title || ''}
          onChange={this.handleChange}
        />
        <input
          name="schaduw"
          type="hidden"
          value={this.props.calculations.rendement.schaduw.title || ''}
          onChange={this.handleChange}
        />
        <input
          name="helling"
          type="hidden"
          value={this.props.calculations.rendement.helling}
          onChange={this.handleChange}
        />
        <GroupedLabeledInput
          className={
            stylesForm['component__form__wrapper__inputBlock--labeled']
          }
          label="Oppervlakte"
          type="number"
          name="oppervlakte"
          value={this.props.calculations.rendement.oppervlakte}
          placeholder={this.state.placeholders.oppervlakte}
          onChange={this.handleChange}
          required={true}
          inline-label={
            <>
              m<sup>2</sup>
            </>
          }
          convert-to="integer"
          mask={createNumberMask({
            prefix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: '.',
            allowDecimal: false,
            allowNegative: false
          })}
        />
        <GroupedLabeledInput
          className={
            stylesForm['component__form__wrapper__inputBlock--labeled']
          }
          label="Elektriciteitsprijs"
          type="number"
          name="elekPrijs"
          value={this.props.calculations.rendement.elekPrijs}
          placeholder={this.state.placeholders.elekPrijs}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          required={true}
          convert-to="currency"
          inline-label={<>€</>}
          mask={[/[0-0]/, ',', /\d/, /\d/]}
        />
        <GroupedLabeledInput
          className={
            stylesForm['component__form__wrapper__inputBlock--labeled']
          }
          label="Jaarlijks verbruik"
          type="number"
          name="stroomverbruik"
          value={this.props.calculations.rendement.stroomverbruik}
          placeholder={this.state.placeholders.stroomverbruik}
          onChange={this.handleChange}
          required={true}
          inline-label={<>kWh</>}
          convert-to="integer"
          mask={createNumberMask({
            prefix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: '.',
            allowDecimal: false,
            allowNegative: false
          })}
        />
        <GroupedLabeledInput
          className={stylesForm.component__form__wrapper__inputBlock}
          selectClassName={
            stylesForm.component__form__wrapper__inputBlock__selectWrapper
          }
          label="Oriëntatie van dak"
          type="select"
          name="orientatie"
          activeItem={this.props.calculations.rendement.orientatie}
          placeholder={this.state.placeholders.orientatie}
          onChange={this.handleSelectChange}
          items={this.state.options.orientatie}
          required={true}
        />
        {this.props.extended && (
          <>
            <GroupedLabeledInput
              className={stylesForm.component__form__wrapper__inputBlock}
              selectClassName={
                stylesForm.component__form__wrapper__inputBlock__selectWrapper
              }
              label="Type Dak"
              type="select"
              name="typeDak"
              activeItem={this.props.calculations.rendement.typeDak}
              placeholder={this.state.placeholders.typeDak}
              onChange={this.handleSelectChange}
              items={this.state.options.typeDak}
            />

            <GroupedLabeledInput
              className={stylesForm.component__form__wrapper__inputBlock}
              selectClassName={
                stylesForm.component__form__wrapper__inputBlock__selectWrapper
              }
              label="Schaduwvorming"
              type="select"
              name="schaduw"
              activeItem={this.props.calculations.rendement.schaduw}
              placeholder={this.state.placeholders.schaduw}
              onChange={this.handleSelectChange}
              items={this.state.options.schaduw}
            />

            <GroupedLabeledInput
              className={
                stylesForm['component__form__wrapper__inputBlock--labeled']
              }
              label="Helling"
              type="number"
              name="helling"
              value={this.props.calculations.rendement.helling}
              placeholder={this.state.placeholders.helling}
              onChange={this.handleChange}
              inline-label={<>Graden</>}
              max={180}
              min={0}
              convert-to="integer"
            />
          </>
        )}
      </>
    );
  }
}

export class SdeCalculatorPersonalInfoComponent extends SdeCalculator {
  state = {
    name: 'Voornaam Achternaam',
    phone: '',
    email: 'email@domein.nl'
  };

  render() {
    return (
      <>
        <input
          name="naam"
          type="hidden"
          value={this.props.calculations.rendement.name}
          onChange={this.handleChange}
        />
        <input
          name="telefoonnummer"
          type="hidden"
          value={this.props.calculations.rendement.phone}
          onChange={this.handleChange}
        />
        <input
          name="email"
          type="hidden"
          value={this.props.calculations.rendement.email}
          onChange={this.handleChange}
        />
        <GroupedLabeledInput
          className={stylesForm.component__form__wrapper__inputBlock}
          label="Uw naam"
          type="text"
          name="name"
          value={this.props.calculations.rendement.name}
          placeholder={this.state.name}
          onChange={this.handleChange}
        />
        <GroupedLabeledInput
          className={stylesForm.component__form__wrapper__inputBlock}
          label="Telefoon"
          type="number"
          name="phone"
          value={this.props.calculations.rendement.phone}
          placeholder={this.state.phone}
          onChange={this.handleChange}
          mask={[
            /[0-9]/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/
          ]}
        />
        <GroupedLabeledInput
          className={stylesForm.component__form__wrapper__inputBlock}
          label="E-mailadres"
          type="email"
          name="email"
          value={this.props.calculations.rendement.email}
          placeholder={this.state.email}
          onChange={this.handleChange}
          required={true}
          // mask={emailMask}
        />
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return { calculateRendement: args => dispatch(calculateRendement(args)) };
};

export default connect(
  state => state,
  mapDispatchToProps
)(SdeCalculator);
export const SdeCalculatorPersonalInfo = connect(
  state => state,
  mapDispatchToProps
)(SdeCalculatorPersonalInfoComponent);
