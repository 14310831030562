import React from 'react';

import styles from './Gallery.scss';

import { imagePath } from '../../utils/assetUtils';

export const NavControls = props => {
  return (
    <section className={styles.gallery__navControls}>
      <ul className={styles.gallery__navControls__list}>
        {/* <li className={styles.gallery__navControls__list_item}></li> */}
      </ul>
    </section>
  );
};

export const ContentIntro = props => {
  return (
    <div className={props.className}>
      <div />
      <div
        className={styles.content_gallery__intro__image}
        style={{ backgroundImage: 'url(' + props.image + ')' }}
      />
    </div>
  );
};

export const FormIntro = props => {
  return (
    <div className={props.className}>
      <div />
      <div
        className={styles.form_gallery__intro__image}
        style={{ backgroundImage: 'url(' + props.image + ')' }}
      />
    </div>
  );
};

export const Gallery = props => {
  return (
    <div
      className={styles.gallery}
      style={{
        backgroundImage: 'url(' + imagePath('images/panels_vector.svg') + ')'
      }}
    >
      <NavControls />
    </div>
  );
};

export const ContentGallery = props => {
  return (
    <div className={styles.content_gallery}>
      <ContentIntro {...props} className={styles.content_gallery__intro} />
      {/* <NavControls /> */}
    </div>
  );
};

export const FormGallery = props => {
  return (
    <div className={styles.form_gallery}>
      <FormIntro {...props} className={styles.form_gallery__intro} />
      {/* <NavControls /> */}
    </div>
  );
};
