import React from 'react';

import { TabbedBlock } from '../../components/Section';

import { initStateContent } from './content';

export const DienstenContent = key => {
  const content = initStateContent().diensten;
  return (
    <TabbedBlock
      key={key}
      vertical={true}
      content={content}
      tabs={content.list}
      activeTab={0}
    />
  );
};

export const ProjectOntwikkeling = props => {
  return (
    <>
      <h2 onClick={props.menuHandler}>Project Ontwikkeling</h2>
      <p>
        Het projectontwikkelingsproces is omvangrijk en bestaat onder meer uit
        het vinden van een geschikte locatie, het aanvragen van subsidie en
        vergunningen en de uiteindelijke bouw. Starborn ontwikkelt met name
        grootschalige zonneparken: op daken, velden en water.
      </p>

      <h3>Dak </h3>
      <p>
        Volgens onderzoek wordt er momenteel slechts een fractie van het
        beschikbaar dakoppervlak gebruikt voor zonnepanelen. Dit betekent dat er
        nog veel meer duurzame energie kan worden opgewekt. Starborn zet zich in
        om dakeigenaren te informeren en te adviseren. We kunnen ook zelf in het
        project investeren, indien dat nodig is.
      </p>

      <p>
        Via www.starborn.nl/calculator kunt u gratis laten berekenen wat het
        rendement is als u zelf investeert en wat eventueel de huuropbrengst kan
        zijn.
      </p>

      <h3>Grond</h3>
      <p>
        Bij het ontwikkelen van zonneparken op land is het creëren van een breed
        maatschappelijk draagvlak cruciaal. Er moet goed worden nagedacht over
        lokale behoeften en de landschappelijke inpassing. Door overleg met
        grondeigenaren, omwonenden en gemeente kan Starborn de wensen en
        voorkeuren van belanghebbenden goed in ogenschouw nemen en de lokale
        betrokkenheid stimuleren. Ons voorkeur gaat uit naar voormalige
        vuilstortplaatsen en braakliggende gronden voor het ontwikkelen van
        zonneparken.
      </p>

      <h3>Water</h3>
      <p>
        Het grootste voordeel van zonneparken op water is dat landbouwgronden
        bespaard kunnen blijven. De uitdagingen zitten met name in de techniek
        en de financiering van drijvende zonneparken. Gelukkig wordt er hard aan
        de weg getimmerd en zien wij steeds meer interesse ontstaan voor
        drijvende zonneparken.
      </p>
    </>
  );
};

export const Consulting = props => {
  return (
    <>
      <h2 onClick={props.menuHandler}>Consulting</h2>
      <p>
        Starborn draagt de energietransitie een warm hart toe en stelt haar
        expertise beschikbaar voor grondeigenaren die zelf een zonnepark willen
        exploiteren. We zijn betrokken bij het gehele traject van ontwikkeling
        en investering. Daarom zijn we goed in staat om u bij te staan bij een
        of meerdere aspecten van het proces.
      </p>
    </>
  );
};

export const Epc = props => {
  return (
    <>
      <h2 onClick={props.menuHandler}>
        Engineering, Production en Construction (EPC)
      </h2>
      <p>
        Het realiseren van een zonnepark is een omvangrijk proces dat bestaat
        uit Engineering, Production en Construction (EPC). Denk hierbij aan het
        prepareren van de grond, de aanleg van draagstructuren, het plaatsen van
        zonnepanelen, het aanleggen van kabels enzovoort. Onze zonneparken
        worden met de nieuwste software ontworpen volgens hoge industrie
        standaarden voor een maximaal rendement binnen de landschappelijke
        kaders. Samen met onze partners zorgen we ervoor dat de zonneparken
        efficiënt en op tijd worden gerealiseerd.
      </p>
    </>
  );
};

export const Zonnepanelen = props => {
  return (
    <>
      <h2 onClick={props.menuHandler}>Zonnepanelen</h2>
      <p>
        Zonnepanelen zetten energie uit zonlicht om in elektrische energie en
        zijn doorgaans blauw of zwart. Er is inmiddels een ruime keuze aan
        verschillende typen zonnepanelen, maar voor projectfinanciering worden
        ze niet allemaal geaccepteerd. Starborn verkoopt alleen Tier 1
        zonnepanelen, aangezien deze door banken worden geaccepteerd.
      </p>
    </>
  );
};

export const Financial = props => {
  return (
    <>
      <h2 onClick={props.menuHandler}>Financiële diensten</h2>
      <p>
        Bent u bezig met het financieringsvraagstuk van uw zonne-energieproject?
        Neem dan vooral contact op met Starborn. Wij helpen u om uw financiële
        rendement te optimaliseren. Bovendien kunnen wij u ondersteunen bij het
        aantrekken van kapitaal, bijvoorbeeld:
      </p>
      <ul>
        <li>vreemd vermogen</li>
        <li>achtergestelde lening</li>
        <li>achtergestelde lening</li>
        <li>mezzanine</li>
        <li>co-ontwikkelaar</li>
        <li>projectacquisitie</li>
      </ul>
    </>
  );
};

export const Aankoopbegeleiding = props => {
  return (
    <>
      <h2 onClick={props.menuHandler}>Aankoopbegeleiding</h2>
      <p>
        Starborn begeleidt investeerders in het kopen van aantrekkelijke
        zonne-energieprojecten in Oost-Europese en Aziatische landen. De
        zonne-energieprojecten leveren doorgaans een hoger rendement. Er zijn
        echter ook de nodige risico’s aan verbonden. Heeft u interesse in zo’n
        project, laat u zich dan goed adviseren. Starborn kan u ondersteunen bij
        de aankoop van projecten in Nederland, Hongarije, Oekraïne, Moldavië,
        Turkije en Polen.
      </p>
    </>
  );
};
