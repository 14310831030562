import React from 'react';
import { Link } from 'react-router-dom';
import Icons from '../Icons';
import stylesIndex from './index.scss';

import './tabs.css';

import { Tab, Tabs } from '@blueprintjs/core';

export const Block = props => {
  const classes = [];
  if (props.classes) {
    props.classes
      .split(' ')
      .map(className => classes.push(stylesIndex[className]));
  }
  return (
    <section
      className={
        (stylesIndex.section ? stylesIndex.section : '') +
        ' ' +
        (props.classes ? classes.join(' ') : '')
      }
      style={props.style}
    >
      <div className={stylesIndex.section__container}>
        <h2 className={stylesIndex.section__container__title}>
          {props.icon && <Icons icon={props.icon} />}
          {props.title}
        </h2>
        <div className={stylesIndex.section__container__content}>
          {props.children}
        </div>
        {!props.page && props.link && (
          <Link to={props.link.url} alt={props.link.alt} {...props.link}>
            {props.link.text}
          </Link>
        )}
      </div>
    </section>
  );
};

export const ColumnBlock = props => {
  const columnClass = stylesIndex.section__container__wrapper__columns__column;
  const columnTitle =
    stylesIndex.section__container__wrapper__columns__column__title;
  const columnContent =
    stylesIndex.section__container__wrapper__columns__column__content;
  const columnLink =
    stylesIndex.section__container__wrapper__columns__column__link;
  return (
    <div className={columnClass ? columnClass : null}>
      <h3 className={columnTitle ? columnTitle : null}>
        {props.icon && <Icons icon={props.icon} />}
        {props.title}
      </h3>
      <div className={columnContent ? columnContent : null}>
        {props.children}
      </div>
      {props.link && (
        <Link
          to={props.link.url}
          alt={props.link.alt}
          className={columnLink ? columnLink : null}
          {...props.link}
        >
          {props.link.text}
        </Link>
      )}
    </div>
  );
};

export const ColumnBlocks = props => {
  const classes = [];
  const options = {
    devider: true,
    colSize: 1,
    colPrefix: 'cols-',
    deviders: true,
    renderChildrenOnly: false,
    ...props.options
  };
  if (props.classes) {
    props.classes
      .split(' ')
      .map(className =>
        stylesIndex[className] ? classes.push(stylesIndex[className]) : false
      );
  }
  const noDeviderClass = !options.deviders
    ? stylesIndex['section__container__columns--no-devider']
    : '';

  return (
    <section
      className={
        (stylesIndex.section ? stylesIndex.section : '') +
        ' ' +
        (props.classes ? classes.join(' ') : '')
      }
    >
      <div className={stylesIndex.section__container}>
        {props.title && (
          <h2 className={stylesIndex.section__container__title}>
            {props.icon && <Icons icon={props.icon} />}
            {props.title}
          </h2>
        )}
        {props.intro && (
          <div className={stylesIndex.section__container__intro}>
            {props.intro}
          </div>
        )}
        <div
          className={
            stylesIndex.section__container__wrapper__columns +
            ' ' +
            stylesIndex[options.colPrefix + options.colSize] +
            ' ' +
            noDeviderClass
          }
        >
          {!options.renderChildrenOnly &&
            props.columns &&
            props.columns.list.map(({ content, ...data }, index) => {
              return (
                <ColumnBlock key={index} {...data}>
                  {content}
                </ColumnBlock>
              );
            })}
        </div>
        <div>{props.children}</div>
      </div>
    </section>
  );
};

export const GridBlocks = props => {
  const classes = [];
  if (props.classes) {
    props.classes
      .split(' ')
      .map(className => classes.push(stylesIndex[className]));
  }
  return (
    <section
      className={
        (stylesIndex.section ? stylesIndex.section : '') +
        ' ' +
        (props.classes ? classes.join(' ') : '')
      }
    >
      <div className={stylesIndex.section__container}>
        {props.title && (
          <h2 className={stylesIndex.section__container__title}>
            {props.icon && <Icons icon={props.icon} />}
            {props.title}
          </h2>
        )}
        {props.children && (
          <div className={stylesIndex.section__container__intro}>
            {props.children}
          </div>
        )}
        <div className={stylesIndex.section__container__gridblocks__wrapper}>
          {props.grids &&
            props.grids.map((GridBlockData, index) => (
              <GridBlock key={index} {...GridBlockData}>
                {props.icon && <Icons icon={props.icon} />}
              </GridBlock>
            ))}
        </div>
      </div>
    </section>
  );
};

export const GridBlock = props => {
  return (
    <div className={stylesIndex.section__container__gridblocks__wrapper__block}>
      {props.children}
    </div>
  );
};

export class TabbedBlock extends React.Component {
  state = {
    selectedTabId: undefined,
    shown: false
  };
  constructor(props) {
    super(props);
    this.changeTab = this.changeTab.bind(this);
    this.showMenu = this.showMenu.bind(this);
  }

  changeTab(tabId) {
    this.setState({
      selectedTabId: tabId,
      shown: false
    });
  }

  showMenu() {
    this.setState(prevState => {
      return {
        ...prevState,
        shown: !prevState.shown
      };
    });
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (
      prevState.selectedTabId === undefined ||
      prevState.selectedTabId > prevProps.tabs.length - 1
    ) {
      this.changeTab(0);
    }
    return true;
  }

  render() {
    return (
      <section className={stylesIndex.section__container__tabbedblocks}>
        <div
          className={stylesIndex.section__container__tabbedblocks__wrapper}
          data-shown={this.state.shown}
        >
          <Tabs
            vertical={this.props.vertical || false}
            className={
              stylesIndex.section__container__tabbedblocks__wrapper__tabs
            }
            id="servicesTab"
            onChange={this.changeTab}
            selectedTabId={this.state.selectedTabId}
          >
            {this.props.tabs &&
              this.props.tabs.map((tabContent, key) => {
                return (
                  <Tab
                    id={key}
                    key={key}
                    title={tabContent.title}
                    panel={tabContent.fullContent({
                      menuHandler: this.showMenu
                    })}
                  />
                );
              })}
          </Tabs>
        </div>
      </section>
    );
  }
}
