import React from 'react';

import { connect } from 'react-redux';
import axios from 'axios';
import queryString from 'query-string';

import { setLoadingState } from '../../modules/transitions';
import { newInfo, newError } from '../../modules/flash';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';
// import emailMask from 'text-mask-addons/dist/emailMask';

import { GroupedLabeledInput } from '../../components/Inputs/Inputs';
import { SubmitButton } from '../../components/Buttons/Buttons';

import { gTagEvent, timeoutTime } from '../../modules/analytics';

import styles from './LandGezocht.scss';
import stylesForm from '../../componentStyles/component.scss';

export class LandGezocht extends React.Component {
  state = {
    placeholder: {
      hectare: '23',
      gemeente: 'Eindhoven',
      typePerceel: 'Landbouwgrond',
      dorp: 'Harfsen',
      verhuurVerkoop: {
        title: 'Pacht',
        value: 'Pacht'
      },
      naam: 'Uw naam',
      telefoon: '0612345678',
      email: 'email@domain.nl'
    },
    data: {
      hectare: '',
      gemeente: '',
      typePerceel: '',
      dorp: '',
      verhuurVerkoop: {
        title: 'Pacht',
        value: 'Pacht'
      },
      naam: '',
      telefoon: '',
      email: ''
    }
  };
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const inputKey = e.target.getAttribute('id');
    const inputValue = e.target.value;
    this.setState(prevState => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          [inputKey]: inputValue
        }
      };
    });
    this.trackFormData();
  }

  handleSelectChange(name, state) {
    this.setState(prevState => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          [name]: state
        }
      };
    });
    this.trackFormData();
  }

  trackFormData() {
    if (this.trackingForm) {
      clearTimeout(this.trackingForm);
    }
    this.trackingForm = setTimeout(() => {
      gTagEvent({
        event: 'handleForm',
        event_category: 'landGezochtForm',
        event_label: JSON.stringify(this.state)
      });
    }, timeoutTime);
  }

  handleSubmit(event) {
    gTagEvent({
      event: 'handleSubmit',
      event_category: 'landGezochtSubmit',
      event_label: 'Landgezocht Form Submit'
    });
    this.props.setLoadingState(true);
    event.preventDefault();
    if (
      this.state.data.naam &&
      this.state.data.email &&
      this.state.data.gemeente &&
      this.state.data.hectare &&
      this.state.data.verhuurVerkoop.value
    ) {
      const postData = {
        ...this.state.data,
        verhuurVerkoop: this.state.data.verhuurVerkoop.value
      };
      axios({
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: 'https://starborn.nl/notify',
        data: queryString.stringify(postData)
      }).then(response => {
        if (response.data && response.data.mail) {
          this.props.setLoadingState(false);
          this.props.newInfo({
            title: `Beste ${this.state.data.naam.split(' ').shift()},`,
            message: (
              <span>
                Uw bericht is succesvol verstuurd! Wij nemen zsm contact met u
                op.
              </span>
            ),
            type: 'info',
            icon: 'tick',
            confirmButtonText: 'Ga verder'
          });
          gTagEvent({
            event: 'handleSubmit',
            event_category: 'landGezochtValidSubmit',
            event_label: 'Landgezocht Form Valid Submit'
          });
        } else {
          this.props.setLoadingState(false);
          this.props.newError({
            title: `Foutmelding`,
            type: 'info',
            intent: 'danger',
            message: <span>Vul alle vereiste velden correct in a.u.b.!</span>,
            icon: 'warning-sign'
          });
          gTagEvent({
            event: 'handleSubmit',
            event_category: 'landGezochtInvalidSubmit',
            event_label: 'Landgezocht Form Invalid Submit'
          });
        }
        return response;
      });
    }
  }

  render() {
    return (
      <form
        ref={ref => (this.form = ref)}
        action="https://starborn.nl/notify"
        method="post"
        target="_blank"
        onSubmit={this.handleSubmit}
        className={
          stylesForm.component__form +
          ' ' +
          (this.props.formPage ? stylesForm['component__form--col-2'] : null)
        }
      >
        <div
          className={
            stylesForm.component__form__wrapper +
            ' ' +
            styles.component__form__wrapper
          }
        >
          <input
            name="email"
            type="hidden"
            value={this.state.data.email}
            placeholder={this.state.placeholder.email}
            onChange={this.handleChange}
          />
          <input
            name="gemeente"
            type="hidden"
            value={this.state.data.gemeente}
            placeholder={this.state.placeholder.gemeente}
            onChange={this.handleChange}
          />
          <input
            name="hectare"
            type="hidden"
            value={this.state.data.hectare}
            placeholder={this.state.placeholder.hectare}
            onChange={this.handleChange}
          />
          <input
            name="typePerceel"
            type="hidden"
            value={this.state.data.typePerceel}
            placeholder={this.state.placeholder.typePerceel}
            onChange={this.handleChange}
          />
          <input
            name="dorp"
            type="hidden"
            value={this.state.data.dorp}
            placeholder={this.state.placeholder.dorp}
            onChange={this.handleChange}
          />
          <input
            name="verhuurVerkoop"
            type="hidden"
            value={
              this.state.data.verhuurVerkoop
                ? this.state.data.verhuurVerkoop.value
                : this.state.placeholder.verhuurVerkoop.value
            }
            onChange={this.handleChange}
          />
          <input
            name="telefoon"
            type="hidden"
            value={this.state.data.telefoon}
            placeholder={this.state.placeholder.telefoon}
            onChange={this.handleChange}
          />
          <input
            name="naam"
            type="hidden"
            value={this.state.data.naam}
            placeholder={this.state.placeholder.naam}
            onChange={this.handleChange}
          />
          {/*  */}
          <GroupedLabeledInput
            className={stylesForm.component__form__wrapper__inputBlock}
            label="Hectare"
            type="number"
            name="hectare"
            placeholder={this.state.placeholder.hectare}
            onChange={this.handleChange}
            convert-to="integer"
            mask={createNumberMask({
              prefix: '',
              includeThousandsSeparator: true,
              thousandsSeparatorSymbol: '.',
              allowDecimal: true,
              allowNegative: false,
              requireDecimal: false,
              decimalSymbol: ','
            })}
            required={true}
          />
          <GroupedLabeledInput
            className={stylesForm.component__form__wrapper__inputBlock}
            label="Type Perceel"
            type="text"
            name="typePerceel"
            placeholder={this.state.placeholder.typePerceel}
            onChange={this.handleChange}
          />
          <GroupedLabeledInput
            className={stylesForm.component__form__wrapper__inputBlock}
            label="Dorp"
            type="text"
            name="dorp"
            placeholder={this.state.placeholder.dorp}
            onChange={this.handleChange}
          />
          <GroupedLabeledInput
            className={stylesForm.component__form__wrapper__inputBlock}
            label="Gemeente"
            type="text"
            name="gemeente"
            placeholder={this.state.placeholder.gemeente}
            onChange={this.handleChange}
            required={true}
          />
          <GroupedLabeledInput
            className={stylesForm.component__form__wrapper__inputBlock}
            selectClassName={
              stylesForm.component__form__wrapper__inputBlock__selectWrapper
            }
            label="Pacht / Verkoop"
            type="select"
            items={[
              { title: 'Pacht', value: 'Pacht' },
              { title: 'Verkoop', value: 'Verkoop' },
              { title: 'Geen Voorkeur', value: 'Geen Voorkeur' }
            ]}
            name="verhuurVerkoop"
            activeItem={this.state.data.verhuurVerkoop}
            placeholder={this.state.placeholder.verhuurVerkoop}
            onChange={this.handleSelectChange}
            required={true}
          />
          <GroupedLabeledInput
            className={stylesForm.component__form__wrapper__inputBlock}
            label="Naam"
            type="text"
            name="naam"
            placeholder={this.state.placeholder.naam}
            onChange={this.handleChange}
            required={true}
          />
          <GroupedLabeledInput
            className={stylesForm.component__form__wrapper__inputBlock}
            label="Telefoonnummer"
            type="number"
            name="telefoon"
            placeholder={this.state.placeholder.telefoon}
            onChange={this.handleChange}
            mask={[
              /[0-9]/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/
            ]}
          />
          <GroupedLabeledInput
            className={stylesForm.component__form__wrapper__inputBlock}
            label="E-mail"
            type="email"
            name="email"
            placeholder={this.state.placeholder.email}
            onChange={this.handleChange}
            // mask={emailMask}
            required={true}
          />
        </div>
        <SubmitButton
          className={stylesForm.component__form__wrapper__submitBtn}
          text="Stuur mij een offerte"
        />
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLoadingState: args => dispatch(setLoadingState(args)),
    newInfo: args => dispatch(newInfo(args)),
    newError: args => dispatch(newError(args))
  };
};

export default connect(
  state => state,
  mapDispatchToProps
)(LandGezocht);
