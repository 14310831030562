import React from 'react';
import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';

import MaskedInput from 'react-text-mask';

import './SelectInput.css';

import styles from './Inputs.scss';

export const Label = props => {
  return (
    <label htmlFor={props.name}>
      {props.label}{' '}
      {props.required && <sup className={styles['input--required']}>*</sup>}
    </label>
  );
};

const INPUT_TYPES = {
  defaultType: 'text',
  select: {
    component: ({ selectClassName, className, ...props }) => (
      <div className={selectClassName}>
        <InputSelect {...props} />
      </div>
    )
  },
  text: {
    component: props => <InputText {...props} />
  },
  number: {
    component: props => <InputText {...props} />
  },
  email: {
    component: props => <InputText {...props} />
  }
};

const InlineLabel = props => {
  return (
    <>
      {props['inline-label'] && (
        <span className={styles.inputInlineLabel__wrapper}>
          {props['inline-label']}
        </span>
      )}
    </>
  );
};

export const InputText = ({ type, ...props }) => {
  return (
    <>
      <InlineLabel {...props} />
      {props.mask ? (
        <MaskedInput
          id={props.name}
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
          {...props}
        />
      ) : (
        <input
          type={type}
          id={props.name}
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
          {...props}
        />
      )}
    </>
  );
};

export class InputSelect extends React.Component {
  state = {
    activeItem: {}
  };

  constructor(props) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  renderSelect = (option, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const text = `${option.title}`;
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={option.title}
        onClick={handleClick}
        text={text}
      />
    );
  };

  handleSelectChange(item, event) {
    event.preventDefault();
    this.setState({
      activeItem: item
    });
    this.props.onChange(this.props.name, item);
  }

  componentDidMount() {
    this.setState({
      activeItem: this.props.activeItem || this.props.items[0]
    });
  }

  render() {
    return (
      <>
        <InlineLabel {...this.props} />
        <Select
          filterable={false}
          activeItem={this.state.activeItem}
          items={this.props.items || []}
          onItemSelect={this.handleSelectChange}
          itemRenderer={this.renderSelect}
          {...this.props}
          ref={ref => (this.refSelect = ref)}
        >
          <Button text={this.state.activeItem.title} rightIcon="caret-down" />
        </Select>
      </>
    );
  }
}

export const GroupedInput = props => {
  return <div className={props.className}>{props.children}</div>;
};

export const GroupedLabeledInput = props => {
  return (
    <GroupedInput className={props.className}>
      <Label name={props.name} label={props.label} {...props} />
      {INPUT_TYPES[props.type].component(props) ||
        INPUT_TYPES[INPUT_TYPES.defaultType].component(props)}
    </GroupedInput>
  );
};
