import React from 'react';
import { Link } from 'react-router-dom';

import stylesIndex from './index.scss';

import { DIENSTEN, OVER_ONS, VACATURES, CONTACT } from '../../constants';

import { imagePath } from '../../utils/assetUtils';

export const Footer = props => {
  return (
    <footer className={stylesIndex.footer}>
      <div className={stylesIndex.footer__container}>
        <ul
          className={`${stylesIndex.footer__container__block} ${
            stylesIndex['footer__container__block--logo']
          }`}
        >
          <li className={stylesIndex.footer__container__block__list__item}>
            <Link to="/">
              <img
                className={
                  stylesIndex.footer__container__block__list__item__logo
                }
                src={imagePath('images/logo.png')}
                alt={process.env.APP_NAME}
              />
            </Link>
          </li>
        </ul>
        <ul
          className={`${stylesIndex.footer__container__block} ${
            stylesIndex['footer__container__block--empty']
          }`}
        />
        <ul
          className={`${stylesIndex.footer__container__block} ${
            stylesIndex['footer__container__block--services']
          }`}
        >
          <li className={stylesIndex.footer__container__block__list__item}>
            <h4
              className={
                stylesIndex.footer__container__block__list__item__header
              }
            >
              Diensten
            </h4>
          </li>
          <li className={stylesIndex.footer__container__block__list__item}>
            <a
              href={DIENSTEN}
              alt=""
              className={stylesIndex.footer__container__block__list__item__link}
            >
              Projectontwikkeling
            </a>
          </li>
          <li className={stylesIndex.footer__container__block__list__item}>
            <a
              href={DIENSTEN}
              alt=""
              className={stylesIndex.footer__container__block__list__item__link}
            >
              Consulting
            </a>
          </li>
          <li className={stylesIndex.footer__container__block__list__item}>
            <a
              href={DIENSTEN}
              alt=""
              className={stylesIndex.footer__container__block__list__item__link}
            >
              EPC
            </a>
          </li>
          <li className={stylesIndex.footer__container__block__list__item}>
            <a
              href={DIENSTEN}
              alt=""
              className={stylesIndex.footer__container__block__list__item__link}
            >
              Zonnepanelen
            </a>
          </li>
          <li className={stylesIndex.footer__container__block__list__item}>
            <a
              href={DIENSTEN}
              alt=""
              className={stylesIndex.footer__container__block__list__item__link}
            >
              Aankoopbegeleiding
            </a>
          </li>
          <li className={stylesIndex.footer__container__block__list__item}>
            <a
              href={DIENSTEN}
              alt=""
              className={stylesIndex.footer__container__block__list__item__link}
            >
              Financiële Diensten
            </a>
          </li>
        </ul>
        <ul
          className={`${stylesIndex.footer__container__block} ${
            stylesIndex['footer__container__block--company']
          }`}
        >
          <li className={stylesIndex.footer__container__block__list__item}>
            <h4
              className={
                stylesIndex.footer__container__block__list__item__header
              }
            >
              Company
            </h4>
          </li>
          <li>
            <a
              href={OVER_ONS}
              alt=""
              className={stylesIndex.footer__container__block__list__item__link}
            >
              Over Ons
            </a>
          </li>
          <li className={stylesIndex.footer__container__block__list__item}>
            <a
              href={VACATURES}
              alt=""
              className={stylesIndex.footer__container__block__list__item__link}
            >
              Career
            </a>
          </li>
          <li className={stylesIndex.footer__container__block__item__devider} />
          <li className={stylesIndex.footer__container__block__list__item}>
            <a
              href={CONTACT}
              alt=""
              className={stylesIndex.footer__container__block__list__item__link}
            >
              Contact
            </a>
          </li>
          <li className={stylesIndex.footer__container__block__list__item}>
            <a
              href="/assets/docs/privacy_statement_13-01-2019.pdf"
              target="_blank"
              alt=""
              className={stylesIndex.footer__container__block__list__item__link}
            >
              Privacy &amp; Terms
            </a>
          </li>
        </ul>
        <ul
          className={`${
            stylesIndex['footer__container__block--bottom-aligned']
          } ${stylesIndex['footer__container__block--copyright']}`}
        >
          <li
            className={stylesIndex['footer__container__block--copyright-text']}
          >
            <h6>All Rights Reserved by Starborn B.V. 2019 (c)</h6>
          </li>
        </ul>
        <ul
          className={`${stylesIndex.footer__container__block} ${
            stylesIndex['footer__container__block--sm']
          }`}
        >
          <li className={stylesIndex.footer__container__block__list__item}>
            <h4
              className={
                stylesIndex.footer__container__block__list__item__header
              }
            >
              @Starborn
            </h4>
          </li>
          <li className={stylesIndex.footer__container__block__list__item}>
            <a
              href="https://linkedin.com/company/starborn"
              alt=""
              className={stylesIndex.footer__container__block__list__item__link}
            >
              LinkedIn
            </a>
          </li>
          <li className={stylesIndex.footer__container__block__list__item}>
            <a
              href="https://facebook.com/starbornbv"
              alt=""
              className={stylesIndex.footer__container__block__list__item__link}
            >
              Facebook
            </a>
          </li>
        </ul>
        <ul
          className={`${stylesIndex.footer__container__block} ${
            stylesIndex['footer__container__block--contact']
          }`}
        >
          <li className={stylesIndex.footer__container__block__list__item}>
            <h4
              className={
                stylesIndex.footer__container__block__list__item__header
              }
            >
              Contact
            </h4>
          </li>
          <li className={stylesIndex.footer__container__block__list__item}>
            <h5
              className={
                stylesIndex[
                  'footer__container__block__list__item__header--small'
                ]
              }
            >
              The Netherlands
            </h5>
            <address
              className={
                stylesIndex.footer__container__block__list__item__address
              }
            >
              Tappersweg 14-28
              <br />
              2031 EV Haarlem
              <br />
              info@starborn.nl
            </address>
          </li>
        </ul>
      </div>
    </footer>
  );
};
