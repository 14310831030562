import React from 'react';

import { connect } from 'react-redux';
import { calculateRendement } from '../../modules/calculations';

import { setLoadingState } from '../../modules/transitions';
import { newInfo, newError } from '../../modules/flash';

import { SubmitButton } from '../../components/Buttons/Buttons';
import stylesForm from '../../componentStyles/component.scss';
import SdeCalculator, {
  SdeCalculatorPersonalInfo
} from '../../containers/SdeCalculator/SdeCalculator';

import { gTagEvent } from '../../modules/analytics';

export class CalculatorContent extends React.Component {
  fetchData = () =>
    new Promise((resolve, reject) => {
      // simulate fetching data
      // setTimeout(() => {
      return resolve(true);
      // }, 5000);
    });

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    this.props.setLoadingState(true);
    // gTagEvent({
    //   event: 'handleSubmit',
    //   event_category: 'sdeCalculatorSubmit',
    //   event_label: JSON.stringify({
    //     email: this.props.calculations.rendement.email,
    //     opp: this.props.calculations.rendement.oppervlakte,
    //     name: this.props.calculations.rendement.name
    //   })
    // });
    if (
      this.props.calculations.rendement.oppervlakte &&
      this.props.calculations.rendement.elekPrijs &&
      this.props.calculations.rendement.stroomverbruik &&
      this.props.calculations.rendement.orientatie.title &&
      // this.props.calculations.rendement.name &&
      // this.props.calculations.rendement.phone &&
      this.props.calculations.rendement.email
    ) {
      gTagEvent({
        event: 'handleSubmit',
        event_category: 'sdeCalculatorValidSubmit',
        event_label: JSON.stringify({
          email: this.props.calculations.rendement.email,
          opp: this.props.calculations.rendement.oppervlakte,
          name: this.props.calculations.rendement.name,
          phone: this.props.calculations.rendement.phone || null
        })
      });
      return true;
    }
    this.props.setLoadingState(false);
    this.props.newError({
      title: `Foutmelding`,
      type: 'info',
      intent: 'danger',
      message: (
        <span>
          Er is een onverwachte fout opgetreden. Controleer a.u.b. of u alle
          velden correct heeft ingevuld.
        </span>
      ),
      icon: 'warning-sign'
    });
    gTagEvent({
      event: 'handleSubmit',
      event_category: 'sdeCalculatorInvalidSubmit',
      event_label: JSON.stringify({
        email: this.props.calculations.rendement.email,
        opp: this.props.calculations.rendement.oppervlakte,
        name: this.props.calculations.rendement.name,
        phone: this.props.calculations.rendement.phone || null
      })
    });
    // if not valid prevent and give @TODO feedback else return true
    return event.preventDefault();
  }

  render() {
    return (
      <form
        ref={ref => (this.form = ref)}
        action="https://starborn.nl/calculator/submit"
        method="post"
        onSubmit={this.handleSubmit}
        className={`${stylesForm.component__form} ${
          stylesForm['component__form--col-2']
        }`}
      >
        <h2>Dak Informatie</h2>
        <div className={stylesForm.component__form__wrapper}>
          <SdeCalculator {...this.props.calculations} extended={true} />
        </div>
        <h2>Contact informatie</h2>
        <div>
          <SdeCalculatorPersonalInfo {...this.props.calculations} />
        </div>
        <SubmitButton
          className={stylesForm.component__form__wrapper__submitBtn}
          text="Mail mij het rapport"
        />
      </form>
    );
  }
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => {
  return {
    calculateRendement: args => dispatch(calculateRendement(args)),
    setLoadingState: args => dispatch(setLoadingState(args)),
    newInfo: args => dispatch(newInfo(args)),
    newError: args => dispatch(newError(args))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculatorContent);
