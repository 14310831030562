import React from 'react';

import { Intro, ContentIntro, FormIntro } from './Elements';
import { Gallery, ContentGallery } from '../Gallery/Gallery';

import stylesIndex from './index.scss';
import stylesElements from './Elements.scss';

export const ContentHeader = ({ content }) => {
  return (
    <header className={stylesIndex.header}>
      <ContentIntro intro={content.main} />
      <ContentGallery image={content.main.header.image} />
    </header>
  );
};

export const FormHeader = ({ content }) => {
  return (
    <header className={stylesIndex.header}>
      <FormIntro intro={content.main} styles={stylesElements} />
    </header>
  );
};

export default props => {
  return (
    <header className={stylesIndex.header}>
      <Intro />
      <Gallery />
    </header>
  );
};
