import React from 'react';

import axios from 'axios';
import queryString from 'query-string';

import { connect } from 'react-redux';
import { calculateRendement } from '../../modules/calculations';

import { setLoadingState } from '../../modules/transitions';
import { newInfo, newError } from '../../modules/flash';

import { SubmitButton } from '../../components/Buttons/Buttons';
import stylesForm from '../../componentStyles/component.scss';
import { QuickSdeCalculator } from '../../containers/SdeCalculator/QuickSdeCalculator';
import { QuickSdeCalculatorPersonalInfo } from '../../containers/SdeCalculator/QuickSdeCalculator';

import { gTagEvent } from '../../modules/analytics';

class CalculatorContent extends React.Component {
  fetchData = () =>
    new Promise((resolve, reject) => {
      // simulate fetching data
      // setTimeout(() => {
      return resolve(true);
      // }, 5000);
    });

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    this.props.setLoadingState(true);
    event.preventDefault();
    if (
      this.props.calculations.rendement.dakoppervlakte.value &&
      (this.props.calculations.rendement.email !== '' ||
        (this.props.calculations.rendement.phone !== '' &&
          this.props.calculations.rendement.phone.length >= 10))
    ) {
      const postData = {
        naam: this.props.calculations.rendement.name,
        email: this.props.calculations.rendement.email,
        telefoonnummer: this.props.calculations.rendement.phone,
        dakoppervlakte: this.props.calculations.rendement.dakoppervlakte.value
      };
      axios({
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: 'https://starborn.nl/quick-calculator/submit',
        data: queryString.stringify(postData)
      }).then(response => {
        if (response.data && response.data.mail) {
          this.props.setLoadingState(false);
          this.props.newInfo({
            title: `Beste ${this.props.calculations.rendement.name
              .split(' ')
              .shift()},`,
            message: (
              <span>
                Uw verzoek is succesvol verstuurd! <br />
                Wij nemen zsm contact met u op.
              </span>
            ),
            type: 'info',
            icon: 'tick',
            confirmButtonText: 'Ga verder'
          });
          gTagEvent({
            event: 'handleSubmit',
            event_category: 'quickSdeCalculatorValidSubmit',
            event_label: JSON.stringify({
              email: this.props.calculations.rendement.email,
              name: this.props.calculations.rendement.name,
              phone: this.props.calculations.rendement.phone || null
            })
          });
        } else {
          this.props.setLoadingState(false);
          this.showSubmitError();
        }
        return response;
      });
    } else {
      this.props.setLoadingState(false);
      this.showSubmitError();
    }
  }

  showSubmitError() {
    this.props.newError({
      title: `Foutmelding`,
      type: 'info',
      intent: 'danger',
      message: (
        <span>
          Controleer a.u.b. of u alle velden correct heeft ingevuld.
          <br />
          <br />U dient uw e-mailadres of telefoonnummer in te voeren zodat wij
          contact met u kunnen opnemen.
        </span>
      ),
      icon: 'warning-sign'
    });
    gTagEvent({
      event: 'handleSubmit',
      event_category: 'quickSdeCalculatorInvalidSubmit',
      event_label: JSON.stringify({
        email: this.props.calculations.rendement.email,
        name: this.props.calculations.rendement.name,
        phone: this.props.calculations.rendement.phone || null
      })
    });
  }

  render() {
    return (
      <form
        ref={ref => (this.form = ref)}
        action="https://starborn.nl/quick-calculator/submit"
        method="post"
        onSubmit={this.handleSubmit}
        className={`${stylesForm.component__form} ${
          stylesForm['component__form--col-2']
        }`}
      >
        <h2>Dak Informatie</h2>
        <div className={stylesForm.component__form__wrapper}>
          <QuickSdeCalculator {...this.props} />
          <QuickSdeCalculatorPersonalInfo {...this.props} />
        </div>
        <SubmitButton
          className={stylesForm.component__form__wrapper__submitBtn}
          text="Neem contact met me op"
        />
      </form>
    );
  }
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => {
  return {
    calculateRendement: args => dispatch(calculateRendement(args)),
    setLoadingState: args => dispatch(setLoadingState(args)),
    newInfo: args => dispatch(newInfo(args)),
    newError: args => dispatch(newError(args))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculatorContent);
